import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import stage1timeline from "../../images/stage1-timeline.jpg"
import solhelp from "../../images/solhelpthefut.jpg"
import wavestowater from "../../images/wavesto.jpg"
import waterglass from "../../images/waterglass.jpg"
import wavesto2 from "../../images/wavesto2.jpg"
import "../../components/prizes.css"
// images
import { StaticImage } from "gatsby-plugin-image"

const Wave = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Waves to Water Prize</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The Water Power Technologies Office (WPTO) at the U.S.
                Department of Energy seeks innovators to design water
                desalination systems powered by marine renewable energy.{" "}
                <a href="https://www.energy.gov/articles/doe-announces-prize-competition-wave-energy-water-desalination">
                  The Waves to Water{" "}
                </a>{" "}
                prize is a 5-Stage competition that offers up to $3.3 million in
                cash prizes, with a goal to demonstrate small, modular,
                cost-competitive desalination systems that use the power of
                ocean waves to provide clean drinking water for disaster
                recovery and for remote and coastal communities.
              </p>
              <p style={{ textAlign: "justify" }}>
                <a href="" style={{ color: "white" }}>
                  Learn more about this prize
                </a>
              </p>

              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/wavestowater">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="eager"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <StaticImage src="../../images/wavesto.jpg" className="img-styl" alt="wavestro" loading="lazy"/>
          <h1 className="mac_h1">The Cable Prize</h1>
          <p className="pcol-fz">
            The Waves to Water Prize is a 5-Stage, $3.3M contest to accelerate
            technology innovation in wave energy powered desalination systems.
            These technologies hold the potential to deliver clean water to
            communities for disaster relief purposes, and to remote communities
            throughout the globe. Over three years, the prize will provide
            innovators a pathway from initial concept, to technical design, to
            prototype, to field testing systems that provide clean, abundant
            drinking water using only waves as a power source. In the final
            stage of the prize, these systems will be tested in partnership with
            the Coastal Studies Institute at Jennette’s Pier.
          </p>
          <div className="col">
            <StaticImage
              className="img-style"
              src="../../images/waterglass.jpg"
              alt="waterglass"
              loading="lazy"
            />
          </div>
        </div>
        <span className="whitespace-half" style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <hr />
            <p className="pcol-fz">
              The American-Made Challenges Prize platform, administered by the
              National Renewable Energy Laboratory, brings together America’s
              world-class research base with its unparalleled entrepreneurial
              support system – consisting of pioneering university teams, dozens
              of energy incubators, and 16 national laboratories – to create a
              sweeping portfolio of innovations primed for private investment
              and commercial scale-up.
            </p>
            <h2 className="mac_h1">Important Dates</h2>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>CONCEPT Stage:</b> June 13, 2019 – September 11, 2019
              </li>
              <li>
                <b>CONCEPT Stage Winners Announcement:</b> November 14, 2019
              </li>
              <li>
                <b>DESIGN Stage:</b>November 14, 2019 – March 13, 2020
              </li>
              <li>
                <b>CONCEPT Stage Winners Announcement:</b> June 8, 2020
              </li>
              <li>
                <b>ADAPT Stage: </b> June 8, 2020 – November 30, 2020
              </li>
              <li>
                <b>CREATE Stage:</b> February 2021 – August 2021
              </li>
              <li>
                <b>DRINK Stage: </b> September 2021 – Spring 2022
              </li>
              <li>
                <b>Grand Prize Winner Announcement:</b> Spring 2022
              </li>
            </ul>
            <p className="pcol-fz">
              All dates are subject to change including contest openings,
              deadlines, and announcements. Final dates for the opening of the
              CREATE and DRINK Stages will be posted here and on the HeroX site.
            </p>
            <p>
              Sign up for updates on our{" "}
              <a href="https://www.herox.com/wavestowater">
                HeroX challenge page.
              </a>
            </p>
          </div>
          <hr />
          <h1 className="mac_h1">Who can Participate</h1>
          <p className="pcol-fz">
            Entrepreneurs, technologists, hardware developers, and investors are
            all welcome and encouraged to join the American Made Challenge
            Network and be part of the ground-breaking change that will
            accelerate innovation in desalination and marine renewable energy
            technologies.
          </p>
        </div>
        <span className="whitespace-half" style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps"> Solutions that help shape the future </h3>
          <StaticImage className="img-styl" src="../../images/wavesto2.jpg" alt="wavesto2" loading="lazy"/>
          <p className="hps">
            Are you a thinker, entrepreneur, facility or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges. Reach out to the
            organizers to find out how you can get involved.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/wavestowater">
              <button type="button" className="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Wave
